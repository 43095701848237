import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core'
//import ngmodel
import { FormsModule } from '@angular/forms'
import { DomSanitizer } from '@angular/platform-browser'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'
import { Editor, NgxEditorModule } from 'ngx-editor'
import { SurveyService } from '../../../_services/surveys.service'
//import cdr from core
import { CommonModule } from '@angular/common'
import { ChangeDetectorRef } from '@angular/core'
import * as html2pdf from 'html2pdf.js'
interface SurveyReport {
  question_id: string
  target_id: string
  analysis: string
  status: number
  text: string
  created_at: string
}
@Component({
  selector: 'app-dashboard',
  standalone: true,
  imports: [NgxEditorModule, FormsModule, CommonModule],
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.scss',
})
export class DashboardComponent implements OnInit, OnDestroy {
  @Input() htmlContent: any
  @Input() targetId: any
  @Input() targets: any
  @Input() results: any
  @Input() questions: any
  @Output() closeModalEvent = new EventEmitter<void>()

  constructor(
    private sanitizer: DomSanitizer,
    public modal: NgbActiveModal,
    private surveyService: SurveyService,
    private cdr: ChangeDetectorRef
  ) {}

  public element
  editor: Editor
  html = ''
  persistGrid: boolean
  isRegen = false

  ngOnInit(): void {
    this.editor = new Editor()
  }
  save(): void {
    this.cdr.detectChanges()
    const surveyDetails = {
      targetId: this.targetId,
      htmlContent: this.htmlContent,
    }
    this.surveyService.saveSurveyReport(surveyDetails).subscribe(() => {
      console.log('saved')
      this.modal.close()
    })
  }
  isDownloadingDashboard = false

  //send regenerate event to parent
  regenerate(regenerate = true) {
    this.isRegen = true

    const questionDetails = {
      regen: regenerate,
      target_ID: this.targetId,
      targets: this.targets,
      questions: this.questions,
      results: this.results,
    }

    console.log('surveyDetails', questionDetails)
    this.surveyService.generateSurveyReport(questionDetails).subscribe(
      (data: SurveyReport) => {
        console.log('data', data)
        this.isRegen = false // Set loading to false when the data is successfully returned
        this.htmlContent = data.text
        this.cdr.detectChanges()
      },
      error => {
        console.error('Error fetching results', error)
        this.isRegen = false // Set loading to false in case of an error
      }
    )
  }
  /**
   * Download the dashboard as an image
   */
  download() {
    this.isDownloadingDashboard = true
    setTimeout(() => {
      const element = document.createElement('div')
      element.innerHTML = this.htmlContent

      const opt = {
        margin: 1,
        filename: 'Compte_rendu.pdf',
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
      }

      html2pdf()
        .from(element)
        .set(opt)
        .save()
        .finally(() => {
          this.isDownloadingDashboard = false
          this.cdr.detectChanges()
        })
    }, 10)
  }
  // make sure to destory the editor
  ngOnDestroy(): void {
    this.editor.destroy()
  }
}
