export default {
  COMMUNITY: 'Communauté',
  ENTERPRISE: 'Entreprise',
  DESCRIPTION: 'Description',
  TITLE: 'Titre',
  ACTIONS: 'Actions',
  MEMBER: 'Membre',
  NBR: 'Nombre',
  FNAME: 'Prénom',
  LNAME: 'Nom',
  CREATED_AT: 'Date de Création',
  IMG: 'Image',
  NAME: 'Nom',
  SEARCH: 'Rechercher',
  IN_ANY_FIELD: 'par tout champ',
  BTN_DELETE: 'Supprimer',
  BTN_SAVE: 'Sauvegarder',
  BTN_CONFIRM: 'Confirmer',
  BTN_CANCEL: 'Annuler',
  BTN_ADD: 'Ajouter',
  ALLOWED_FILE_TYPES: 'Types de fichiers autorisés',
  THE_FIELD: 'Le champ ',
  INPUT_VALIDATION: {
    MIN: `doit avoir au moins {{min}} charactères`,
    MAX: `doit avoir au plus {{max}} charactères`,
  },
  SHOWING_ROWS_TABLE: 'Affichage des rangs {{from}} à {{to}} sur {{of}}',
  OPERATION_SUCCESS: 'Opération réussie!',
  OPERATION_FAILED: "L'opération a échouée!",
  OPERATION_WARNING:
    "Ce stratégie est disponible dans une offre supérieure! Merci de passer à l'offre premium pour pouvoir l'utiliser!",
  EXAMPLE: 'Exemple',
  LANG: 'Langue',
  DAY_OF_WEEK: {
    MON: 'Lundi',
    TUE: 'Mardi',
    WED: 'Mercredi',
    THU: 'Jeudi',
    FRI: 'Vendredi',
    SAT: 'Samedi',
    SUN: 'Dimanche',
  },
  ADDRESS: 'Adresse',
  YES: 'Oui',
  NO: 'Non',
  FROM: 'De',
  TO: 'À',
  HEIGHT: 'Longeur',
  WIDTH: 'Largeur',
  COLOR: 'Couleur',
  ERRORS: {
    OCCURED_TRY_AGAIN: 'Une erreur est survenue, merci de réessayer...',
    AI_QUOTA_EXCEEDED: "Votre quota d'IA est dépassé. Merci de nous contacter pour demander une augmentation.",
  },
  VIEW: 'Voir',
  EDIT: 'Modifier',
  DELETE: 'Supprimer',
  CREATE: 'Créer',
  SAVE: 'Sauvegarder',
  CANCEL: 'Annuler',
  CLOSE: 'Fermer',
  CONFIRM: 'Confirmer',
  ADD: 'Ajouter',
  ASSIGN: 'Assigner',
}
