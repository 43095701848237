export default {
  'Fiches de poste': 'Fiches de poste',
  'Fiche de poste': 'Fiche de poste',
  'Nouvelle fiche de poste': 'Nouvelle fiche de poste',
  'Assigner à des collaborateurs': 'Assigner à des collaborateurs',
  'Aptitudes et compétences essentielles :': 'Aptitudes et compétences essentielles :',
  'Connaissances essentielles :': 'Connaissances essentielles :',
  'Aptitudes et compétences optionnelles :': 'Aptitudes et compétences optionnelles :',
  'Connaissances optionnelles :': 'Connaissances optionnelles :',
  'Créer une fiche poste': 'Créer une fiche poste',
  'Modifier la fiche poste': 'Modifier la fiche poste',
  'Supprimer la fiche poste': 'Supprimer la fiche poste',
  'Détails de la fiche poste': 'Détails de la fiche poste',
  'Êtes-vous sûr de vouloir supprimer cette fiche poste ?': 'Êtes-vous sûr de vouloir supprimer cette fiche poste ?',
}
