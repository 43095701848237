export default {
  CIBLES: {
    TITRE: 'Ciblage des formations',
    ACTIVER: 'Activer Ciblage',
    RECHERCHER: 'Rechercher',
    PAR_NOM: 'par nom',
    NOM_FORMATION: 'Nom de la formation',
    STATISTIQUE: 'Statistique',
    CIBLE: 'Cible',
    DATE_DEBUT: 'Date de début',
    DATE_FIN: 'Date de clôture',
    TYPE: 'Type',
    ACTIONS: 'ACTIONS',
    PERSONNE: 'Personnes ciblées',
    ACHEVE: 'Achevé',
    DEACTIVE: 'Déactivé',
    ACTIVE: 'Activé',
    EXPIRE: 'Expiré',
    RESULTAT: 'Résultats',
    DEACTIVER: 'Désactiver',
    REACTIVER: 'Réactiver',
  },
  FORMATION_COLLABORATEUR: {
    FILTRES: 'Filtres',
    RECHERCHE: 'Recherche',
    RECHERCHE_TITRE_PLACEHOLDER: 'Rechercher par titre',
    PARTICIPANTS: 'Participants',
    DOMAINE: 'Domaine',
    TOUS_DOMAINES: 'Tous les domaines',
    CERTIFICAT: 'Certificat',
    TOUS: 'Tous',
    AVEC_CERTIF: 'Avec certification',
    SANS_CERTIF: 'Sans certification',
    STATUTS: 'Statut',
    TOUS_STATUTS: 'Tous les statuts',
    PRESENTIEL: 'Présentiel',
    EN_LIGNE: 'En Ligne',
    FILTRER: 'Filtrer',
    REINITIALISER: 'Réinitialiser',
    MES_FORMATIONS: 'Mes Formations',
    DUREE: 'Durée',
    COMPETENCES: 'Compétences acquises',
    VOIR_PLUS: 'Voir plus',
    OUVRIR: 'Ouvrir',
    INSCRIRE: "S'inscrire",
    COMMENCER: 'Commencer',
    TERMINER: 'Terminer',
    TERMINE: 'Terminé',
    EXPIRED: 'Expiré',
  },
  CATALOGUE_FORMATION: {
    ACTIF: 'Actif',
    INACTIF: 'Inactif',
    TITRE: 'Catalogue des formations',
    AJOUTER_FORMATION: 'Ajouter une formation',
    AUCUN_FORMATION: "Aucune formation n'est disponible pour le moment.",
    MODIFIER: 'Modifier',
    DEACTIVER: 'Déactiver',
    REACTIVER: 'Réactiver',
  },
  RESULTAT_CIBLAGE: {
    RESULTAT: 'Résultat',
    NOM_PRENOM: 'Nom et Prénom',
    DATE_DETBUT: 'Date de début',
    DATE_FIN: 'Date de fin',
    STATUS: 'Statut',
    CERTIFICAT: 'Certificat',
    EN_COURS: 'En cours',
    TERMINE: 'Terminé',
    PAS_ENCORE: 'Pas encore terminé',
    VERIFIER: 'Vérifier',
  },
  MES_INTEGRATION_EXTERNE: {
    TITLE: 'Mes Intégrations Externes',
    AJOUTER_OUTILS_EXTERNE: 'Ajouter un outils externe',
    DOMAIN: 'Domaine',
    API_KEY: 'Clé API',
    DERNIERE_ACTUALISATION: 'Dernière actualisation',
    UTILISATEURS: 'Utilisateurs',
    FORMATIONS: 'Formations',
    AVANCEMENT: 'Avancement',
    CONGES: 'Congés',
    SYNC_USERS: 'Synchroniser les utilisateurs',
    SYNC_COURSES: 'Synchroniser les formations',
    SYNC_COURSE_COMPLETION: 'Synchroniser les avancements des formations',
    SYNC_CONGES: 'Synchroniser les congés',
    NO_INTEGRATIONS_MESSAGE: "Vous n'avez pas encore intégré d'outils!",
  },
  MES_COMPETENCES: {
    TITRE: 'Mes compétences',
    AJOUTER_COMPETENCE: 'Ajouter une compétence',
    PAS_DE_COMPETENCES: 'Pas de compétences définies',
    COMMENCER_FORMATION: "Pour l'instant, vous n'avez pas de compétences, veuillez commencer une formation.",
    VOIR_FORMATIONS_DISPONIBLES: 'Voir les formations disponibles',
    RECHERCHE_PAR_NOM: 'Recherche par nom',
    FILTRER_PAR_DOMAINE: 'Filtrer par domaine',
    SUPPRIMER: 'Supprimer',
    MODIFIER: 'Modifier',
  },
  MES_PROGRESSONS: {
    CIBLERFORMATION: 'Attribuer la formation',
    ESTCIBLE: 'Formation déjà attribuée',
    TITRE: 'Mes progressions',
    AUCUNE_PROGRESSION: 'Aucune formation en cours',
    COMMENCER_FORMATION: "Pour l'instant, il n'y a pas de progression en cours, veuillez commencer une formation.",
    VOIR_FORMATIONS_DISPONIBLES: 'Voir les formations disponibles',
    RECHERCHE_PAR_NOM: 'Recherche par nom',
    FILTRER_PAR_STATUS: 'Filtrer par status',
    FILTRER: 'Filtrer',
    RECOMANDE: 'Recommandée',
    TERMINÉ: 'Terminé',
    EN_COURS: 'En cours',
    NON_COMMENCÉ: 'Non commencé',
    RECOMNDER: 'Recommandée',
    FORMATION_TERMINE: 'Félicitation! vous avez terminé 100% de ce cours',
    FORMATION_EN_COURS1: 'Vous avez terminé',
    FORMATION_EN_COURS2: 'de ce cours',
    TOTAL_UNITÉS: 'Total des unités',
    TEMPS_PASSÉ: 'Temps passé',
    MOYENNE_SCORE: 'Moyenne des scores',
    VOIR_LA_PROGRESSION: 'Voir la progression',
  },
  MODAL_AJOUTER_FORMATION: {
    TITRE: 'Ajouter une formation',
    IMAGE: 'Image',
    TITRE_FORMATION: 'Titre',
    TYPE_FORMATION: 'Type de formation',
    TYPE_FORMATION_EN_LIGNE: 'En ligne',
    TYPE_FORMATION_PRESENTIEL: 'En présentiel',
    URL_FORMATION: 'URL de la formation',
    LIEU: 'Lieu',
    DESCRIPTION: 'Description',
    DUREE: 'Durée',
    DUREE_MINUTES: 'minutes',
    DOMAINE: 'Domaine',
    CHOISIR_DOMAINE: 'Choisir un domaine',
    LIST_COMPETENCES: 'Liste des compétences',
    PRE_REQUIS: 'Pré-requis',
    DIFFICULTE: 'Difficulté',
    CERTIFICATION: 'Certification exigée',
    TITRE_PLACEHOLDER: 'Titre de la formation',
    URL_PLACEHOLDER: 'URL de la formation',
    LIEU_PLACEHOLDER: 'Lieu de la formation',
    DESCRIPTION_PLACEHOLDER: 'Description de la formation',
    DUREE_PLACEHOLDER: 'Durée de la formation en minutes',
    PRE_REQUIS_PLACEHOLDER: 'Pré-requis de la formation',
    ERREUR_TITRE_REQUIS: 'Le titre est requis',
    ERREUR_TITRE_LONGUEUR_MIN: 'Le titre doit comporter au moins 5 caractères',
    ERREUR_TITRE_LONGUEUR_MAX: 'Le titre doit comporter au plus 50 caractères',
    ERREUR_TYPE_FORMATION_REQUIS: 'Le type de formation est requis',
    ERREUR_URL_REQUIS: "L'URL est requise",
    ERREUR_URL_INVALIDE: "L'URL est invalide",
    ERREUR_LIEU_REQUIS: 'Le lieu est requis',
    ERREUR_LIEU_LONGUEUR_MIN: 'Le lieu doit comporter au moins 5 caractères',
    ERREUR_LIEU_LONGUEUR_MAX: 'Le lieu doit comporter au plus 50 caractères',
    ERREUR_DESCRIPTION_REQUIS: 'La description est requise',
    ERREUR_DESCRIPTION_LONGUEUR_MIN: 'La description doit comporter au moins 30 caractères',
    ERREUR_DESCRIPTION_LONGUEUR_MAX: 'La description doit comporter au plus 500 caractères',
    ERREUR_DUREE_REQUIS: 'La durée est requise',
    ERREUR_DUREE_MIN: 'La durée doit être supérieure à 20 minutes',
    ERREUR_DOMAINE_REQUIS: 'Le domaine est requis',
    ERREUR_COMPETENCES_REQUIS: 'Les compétences sont requises',
    ERREUR_PRE_REQUIS_LONGUEUR_MIN: 'Les pré-requis doivent comporter au moins 3 caractères',
    ERREUR_PRE_REQUIS_LONGUEUR_MAX: 'Les pré-requis doivent comporter au plus 500 caractères',
    FERMER: 'Fermer',
    EN_COURS: 'En cours',
    AJOUTER: 'Ajouter',
    PAS_COMPETENCES: 'Pas de compétences pour ce domaine',
    DEBUTANT: 'Débutant',
    INTERMEDIAIRE: 'Intermédiaire',
    EXPERT: 'Expert',
    SUCCESS_UPLOAD_TS: 'Image téléchargée avec succès',
    ERROR_UPLOAD_TS: "Erreur lors du téléchargement de l'image",
    SUCCESS_ADD_TS: 'Formation ajoutée avec succès',
    ERROR_ADD_TS: "Erreur lors de l'ajout de la formation",
    AUTRES_INFORMATIONS: 'Autres informations',
  },
  MODAL_MODIFIER_FORMATION: {
    TITRE: 'Modifier une formation',
    IMAGE: 'Image',
    TITRE_FORMATION: 'Titre',
    TYPE_FORMATION: 'Type de formation',
    TYPE_FORMATION_EN_LIGNE: 'En ligne',
    TYPE_FORMATION_PRESENTIEL: 'En présentiel',
    URL_FORMATION: 'URL de la formation',
    LIEU: 'Lieu',
    DESCRIPTION: 'Description',
    DUREE: 'Durée',
    DUREE_MINUTES: 'minutes',
    DOMAINE: 'Domaine',
    CHOISIR_DOMAINE: 'Choisir un domaine',
    LIST_COMPETENCES: 'Liste des compétences',
    PRE_REQUIS: 'Pré-requis',
    DIFFICULTE: 'Difficulté',
    CERTIFICATION: 'Certification exigée',
    TITRE_PLACEHOLDER: 'Titre de la formation',
    URL_PLACEHOLDER: 'URL de la formation',
    LIEU_PLACEHOLDER: 'Lieu de la formation',
    DESCRIPTION_PLACEHOLDER: 'Description de la formation',
    DUREE_PLACEHOLDER: 'Durée de la formation en minutes',
    PRE_REQUIS_PLACEHOLDER: 'Pré-requis de la formation',
    ERREUR_TITRE_REQUIS: 'Le titre est requis',
    ERREUR_TITRE_LONGUEUR_MIN: 'Le titre doit comporter au moins 5 caractères',
    ERREUR_TITRE_LONGUEUR_MAX: 'Le titre doit comporter au plus 50 caractères',
    ERREUR_TYPE_FORMATION_REQUIS: 'Le type de formation est requis',
    ERREUR_URL_REQUIS: "L'URL est requise",
    ERREUR_URL_INVALIDE: "L'URL est invalide",
    ERREUR_LIEU_REQUIS: 'Le lieu est requis',
    ERREUR_LIEU_LONGUEUR_MIN: 'Le lieu doit comporter au moins 5 caractères',
    ERREUR_LIEU_LONGUEUR_MAX: 'Le lieu doit comporter au plus 50 caractères',
    ERREUR_DESCRIPTION_REQUIS: 'La description est requise',
    ERREUR_DESCRIPTION_LONGUEUR_MIN: 'La description doit comporter au moins 30 caractères',
    ERREUR_DESCRIPTION_LONGUEUR_MAX: 'La description doit comporter au plus 500 caractères',
    ERREUR_DUREE_REQUIS: 'La durée est requise',
    ERREUR_DUREE_MIN: 'La durée doit être supérieure à 20 minutes',
    ERREUR_DOMAINE_REQUIS: 'Le domaine est requis',
    ERREUR_COMPETENCES_REQUIS: 'Les compétences sont requises',
    ERREUR_PRE_REQUIS_LONGUEUR_MIN: 'Les pré-requis doivent comporter au moins 3 caractères',
    ERREUR_PRE_REQUIS_LONGUEUR_MAX: 'Les pré-requis doivent comporter au plus 500 caractères',
    FERMER: 'Fermer',
    EN_COURS: 'En cours',
    MODIFIER: 'Modifier',
    PAS_COMPETENCES: 'Pas de compétences pour ce domaine',
    DEBUTANT: 'Débutant',
    INTERMEDIAIRE: 'Intermédiaire',
    EXPERT: 'Expert',
    SUCCESS_UPLOAD_TS: 'Image téléchargée avec succès',
    ERROR_UPLOAD_TS: "Erreur lors du téléchargement de l'image",
    SUCCESS_UPDATE_TS: 'Formation modifiée avec succès',
    ERROR_UPDATE_TS: 'Erreur lors de la modification de la formation',
  },
  MODAL_DEACTIVER_FORMATION: {
    TITRE: 'Désactiver une formation',
    CONFIRMATION: 'Êtes-vous sûr de vouloir désactiver cette formation ?',
    FERMER: 'Fermer',
    DEACTIVER: 'Désactiver',
    SUCCESS_DEACTIVER_TS: 'Formation supprimée avec succès',
    ERROR_DEACTIVER_TS: 'Erreur lors de la suppression de la formation',
  },
  MODAL_ACTIVER_FORMATION: {
    TITRE: 'Activer une formation',
    CONFIRMATION: 'Êtes-vous sûr de vouloir activer cette formation ?',
    FERMER: 'Fermer',
    ACTIVER: 'Activer',
    SUCCESS_ACTIVATE_TS: 'Formation supprimée avec succès',
    ERROR_ACTIVATE_TS: 'Erreur lors de la suppression de la formation',
  },
  COMPONENT_AUCUNE_FORMATION: {
    TITRE: 'Aucun cours disponible',
    MESSAGE1: "Nous sommes désolés pour le désagrément, mais il n'y a aucun cours disponible pour le moment.",
    MESSAGE2: "Veuillez attendre que l'administrateur ajoute un nouveau cours.",
    REDIRECT: 'Aller au tableau de bord',
  },
  MODAL_DETAILS_FORMATION: {
    DOMAINE: 'Domaine',
    DUREE: 'Durée',
    NIVEAU: 'Niveau',
    DEBUTANT: 'Débutant',
    INTERMEDIAIRE: 'Intermédiaire',
    EXPERT: 'Expert',
    CERTIFICATION: 'Certification',
    OUI: 'Oui',
    NON: 'Non',
    DESCRIPTION: 'Description',
    NOM_FORMATEUR: 'Nom du formateur',
    LIEU: 'Lieu',
    URL_FORMATION: 'URL de la formation',
    COMPETENCES: 'Compétences',
    FERMER: 'Fermer',
  },
  MODAL_CHOISIR_INTEGRATION: {
    TITRE: 'Ajouter une intégration',
    FERMER: 'Fermer',
  },
  MODAL_AJOUTER_INTEGRATION: {
    TITLE: "Options d'intégration pour",
    API_KEY_LABEL: 'Clé API',
    API_KEY_PLACEHOLDER: 'Entrer la clé API',
    API_KEY_TOOLTIP:
      "C'est ici que vous pouvez entrer votre clé API. La clé API est nécessaire pour accéder à l'API et doit rester confidentielle. Vous le trouverez dans vos paramètres de compte.",
    API_KEY_REQUIRED_ERROR: 'La clé API est requise.',
    DOMAIN_LABEL: 'Domaine',
    DOMAIN_PLACEHOLDER: 'Entrer',
    DOMAIN_REQUIRED_ERROR: 'Le domaine est requis.',
    SYNCHRONISATIONS_LABEL: 'Synchronisations',
    USERS_LABEL: 'Utilisateurs',
    COURSES_LABEL: 'Formations',
    COURSE_PROGRESS_LABEL: 'Progression des formations',
    CONGES_LABEL: 'Congés',
    CHECK_CONNECTION_BUTTON: 'Vérifier la connexion',
    ADD_BUTTON: 'Ajouter',
    CLOSE_BUTTON: 'Fermer',
  },
  MODAL_MODIFIER_INTEGRATION: {
    TITLE: "Modifier l'intégration de",
    API_KEY_LABEL: 'Clé API',
    API_KEY_PLACEHOLDER: 'Entrer la clé API',
    API_KEY_REQUIRED_ERROR: 'La clé API est requise.',
    DOMAIN_LABEL: 'Domaine',
    DOMAIN_PLACEHOLDER: 'Entrer',
    DOMAIN_REQUIRED_ERROR: 'Le domaine est requis.',
    SYNCHRONISATIONS_LABEL: 'Synchronisations',
    USERS_LABEL: 'Utilisateurs',
    COURSES_LABEL: 'Formations',
    COURSE_PROGRESS_LABEL: 'Progression des formations',
    CONGES_LABEL: 'Congés',
    CHECK_CONNECTION_BUTTON: 'Vérifier la connexion',
    EDIT_BUTTON: 'Modifier',
    CLOSE_BUTTON: 'Fermer',
  },
  MODAL_AJOUTER_COMPETENCE: {
    COMPETENCE_ADDED: 'Compétence ajoutée avec succès',
    ADDEDERROR: 'Une erreur est survenue',
    MODIFIER: 'Modifier',
    NIVEAUCOMPETENCE: 'Niveau de compétence(1: Débutant, 2: Intermédiaire, 3: Avancé, 4: Expert, 5: Expert Absolu):',
    NIVEAUCOMPETENCEATTRIBUE: 'Niveau de compétence donné par responsable:',
    NVCOMPTENCE: 'Niveau de compétence',
    NIVEAU: 'Niveau de compétence(1: Débutant, 2: Intermédiaire, 3: Avancé, 4: Expert, 5: Expert Absolu)',
    TITRE: 'Ajouter une compétence',
    TITREEDIT: 'Modifier une compétence',
    CHOISIR: 'Que souhaitez-vous ajouter ?',
    ACQUISES: 'Compétences acquises',
    SOUHAITEES: 'Compétences souhaitées',
    CHOISISSEZ: 'Choisissez',
    DOMAINE: 'Domaine',
    COMPETENCE: 'Compétence',
    CHOISIR_DOMAINE: 'Choisir un domaine',
    CHOISIR_COMPETENCE: 'Choisir une compétence',
    FERMER: 'Fermer',
    AJOUTER: 'Ajouter',
  },
  MODAL_CONFIRM_DESACTIVATION: {
    TITLE: 'Êtes-vous sûr de vouloir désactiver ce ciblage ?',
    DEACTIVATE: 'Désactiver',
    CLOSE: 'Fermer',
  },
  MODAL_SUPPRESSION: {
    TITRE: 'Êtes-vous sûr de vouloir supprimer?',
    MESSAGE1: 'Cette action ne peut pas être annulée.',
    MESSAGE2:
      'La suppression de cette intégration supprimera définitivement celle-ci de votre compte. Êtes-vous sûr de vouloir continuer?',
    FERMER: 'Fermer',
    SUPPRIMER: 'Supprimer',
  },
  MODAL_INSCRIPTION_FORMATION: {
    TITRE_MODAL: "S'inscrire a la Formation",
    CONFIRMATION: 'Êtes-vous sûr de vouloir rejoindre cette formation ?',
    FERMER: 'Fermer',
    INSCRIRE: "S'inscrire",
  },
  MODAL_LOADING: {
    TITRE: "Votre demande d'intégration est en cours, merci de votre patience.",
  },
  MODAL_REACTIVATION_CIBLAGE: {
    TITRE: 'Êtes-vous sûr de vouloir réactiver ce ciblage?',
    REACTIVER: 'Réactiver',
    FERMER: 'Fermer',
  },
  MODAL_TERMINER_FORMATION: {
    TITRE: 'Formation terminé',
    EVALUER_FORMATION: 'Évaluer cette formation',
    RATING_REQUIRED: 'Le rating est requis',
    AJOUTER_COMMENTAIRE: 'Ajouter un commentaire',
    COMMENTAIRE_REQUIRED: 'Le commentaire est requis',
    TELECHARGER_CERTIFICAT:
      "Cette formation nécessite le téléchargement d'un certificat à la fin de la formation, merci de sélectionner le fichier.",
    FERMER: 'Fermer',
    TERMINER: 'Terminer',
  },
  MODAL_UNIT_PROGRESS: {
    RESULTAT: 'Résultats',
    UNITS: 'Unités',
    TOTAL_UNITS: 'Unités totales',
    COMPLETED_UNITS: 'Unités terminées',
    PROGRESS_UNITS: 'Unités en cours',
    NOT_STARTED_UNITS: 'Unités non commencées',
    PROGRESSION: 'Progression',
    TOTAL_SCORE: 'Score total',
    TOTAL_TIME: 'Temps total',
    DATE_FIN: 'Date de fin',
    CERTIFICATE: 'Certificat',
    COMPLETED: 'Terminé',
    NOT_STARTED: 'Non commencé',
    IN_PROGRESS: 'En cours',
    COMPLETION_DATE: 'Date de complétion',
    DURATION: 'Durée',
    SCORE: 'Score',
    CLOSE: 'Fermer',
  },
}
