export default {
  COMMUNITY: 'Community',
  ENTERPRISE: 'Enterprise',
  MEMBER: 'Member',
  DESCRIPTION: 'Description',
  TITLE: 'Title',
  ACTIONS: 'Actions',
  NBR: 'Number',
  FNAME: 'First Name',
  LNAME: 'Last Name',
  CREATED_AT: 'Created At',
  IMG: 'Image',
  NAME: 'Name',
  SEARCH: 'Search',
  IN_ANY_FIELD: 'in any field',
  BTN_DELETE: 'Delete',
  BTN_SAVE: 'Save',
  BTN_CONFIRM: 'Confirm',
  BTN_CANCEL: 'Cancel',
  BTN_ADD: 'Add',
  ALLOWED_FILE_TYPES: 'Allowed file types',
  THE_FIELD: 'Field ',
  INPUT_VALIDATION: {
    MIN: ` should have at least {{min}} characters`,
    MAX: ` should have at most {{max}} characters`,
  },
  SHOWING_ROWS_TABLE: 'Showing rows {{from}} to {{to}} of {{of}}',
  OPERATION_SUCCESS: 'Operation was successful!',
  OPERATION_FAILED: 'Operation failed!',
  OPERATION_WARNING: 'This strategy is available in a premium plan! Please upgrade to premium plan to use it!',

  EXAMPLE: 'Example',
  LANG: 'Language',
  DAY_OF_WEEK: {
    MON: 'Monday',
    TUE: 'Tuesday',
    WED: 'Wednesday',
    THU: 'Thursday',
    FRI: 'Friday',
    SAT: 'Saturday',
    SUN: 'Sunday',
  },
  ADDRESS: 'Address',
  YES: 'Yes',
  NO: 'No',
  FROM: 'From',
  TO: 'To',
  HEIGHT: 'Height',
  WIDTH: 'Width',
  COLOR: 'Color',
  ERRORS: {
    OCCURED_TRY_AGAIN: 'An error occurred, please try again!',
    AI_QUOTA_EXCEEDED: 'Your AI quota is exceeded. Please contact us to request an increase.',
  },
  VIEW: 'View',
  EDIT: 'Edit',
  DELETE: 'Delete',
  CREATE: 'Create',
  SAVE: 'Save',
  CANCEL: 'Cancel',
  CLOSE: 'Close',
  CONFIRM: 'Confirm',
  ADD: 'Add',
  ASSIGN: 'Assign',
}
