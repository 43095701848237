import { HttpClient, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router'
import { Observable } from 'rxjs'
import { getSessionFromLocalStorage } from 'src/app/utils/getSessionFromLocalStorage'
import { environment } from 'src/environments/environment'
import { UserModel } from '../_models/user.model'
import { AuthService } from './auth.service'
const Abonnement_Routes = {
  community_actif: ['/community', '/community/gestion', '/community/mycommunities', ,],
  entreprise_annuaire_actif: ['/collaborateurs/mes-collegue'],
  evenement_actif: ['/events', '/events/gerer', '/evenement/actif'],
  onboarding_actif: ['/strategie/onboarding'],
  idea_actif: ['/ideas', '/ideas/ideas', '/ideas/adminIdeas'],
  question_reponse_actif: ['/questions', '/questions/question-recue', '/questions/mes-questions'],
  formation_actif: [
    '/formations',
    '/formations/mes-formations',
    '/formations/cibles',
    '/formations/formations-collaborateurs',
  ],
}
@Injectable({ providedIn: 'root' })
export class AuthGuard {
  protected authLocalStorageToken = `${environment.appVersion}-${environment.USERDATA_KEY}`
  constructor(private router: Router, private authService: AuthService, private http: HttpClient) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    // localStorage.setItem('returnUrl', JSON.stringify('/'))
    const currentUser = this.authService.currentUserValue

    if (currentUser) {
      var d = new Date()

      //current user n exist pas
      var expire_date = new Date(currentUser[0].expire_date)
    }
    if (currentUser && currentUser[0].session_status != 0 && expire_date > d) {
      // check if route is restricted by role
      if (route.data.IdRole_Role && route.data.IdRole_Role.indexOf(currentUser[0].IdRole_Role) === -1) {
        // role not authorised so redirect to home page

        this.router.navigate(['/auth/login'], {
          queryParams: {},
        })

        return false
      }

      if (this.router.url.indexOf('external=true') > -1) {
        // get full url with query params
        const url = new URL(window.location.href)
        const source = url.searchParams.get('source')
        const userId = url.searchParams.get('userId')
        const userEmail = url.searchParams.get('userEmail')
        const token = url.searchParams.get('token')

        this.http
          .post<any>(
            `${environment.apiUrl}/auth/ext-chatbot/register`,
            {
              source,
              userId,
              userEmail,
              token,
            },
            { params: new HttpParams().set('sessionid', getSessionFromLocalStorage()) }
          )
          .subscribe({
            next: _ => {
              return this.router.navigate(['/ext-auth-success'])
            },
            error: error => {
              console.log(error)
              return this.router.navigate(['/ext-auth-success'])
            },
          })
      }

      //Vérification des permissions
      //Vérification des permissions
      if (route.data?.CD_Permission) {
        //current user est tableau des objest:exmple [{nom:khaled,CD_Permission:'rrr},{nom:ali,CD_Permission:'xxr'}]
        //extract CD_Permission of this user
        const userPermissions = currentUser[0].CD_Permission || [] // Assurez-vous que CD_Permission est bien un tableau dans votre modèle utilisateur
        const hasPermission = route.data.CD_Permission.some(permission => userPermissions.includes(permission))
        if (hasPermission || currentUser[0].IdRole_Role == 0) {
          return true
        }
        if (!hasPermission && currentUser[0].IdRole_Role != 0) {
          this.router.navigate(['/dashboard'], { queryParams: {} })
          return false
        }
      }

      this.verifAbonnement(currentUser)

      return true
    }

    if (this.router.url.indexOf('external=true') === -1)
      this.router.navigate(['/auth/login'], {
        queryParams: { returnUrl: state.url },
      })
    // not logged in so redirect to login page with the return url

    return false
  }
  verifAbonnement(currentUser: UserModel) {
    //get current path of browser
    let current_url = window.location.pathname
    const {
      community_actif,
      entreprise_annuaire_actif,
      evenement_actif,
      idea_actif,
      onboarding_actif,
      question_reponse_actif,
      formation_actif,
    } = currentUser[0]
    const abonnement = {
      community_actif,
      entreprise_annuaire_actif,
      evenement_actif,
      idea_actif,
      onboarding_actif,
      question_reponse_actif,
      formation_actif,
    }
    for (const [key, value] of Object.entries(abonnement)) {
      if (value != 1 && Abonnement_Routes[key].includes(current_url)) {
        this.router.navigate(['/error/404'])
      }
    }

    //le cas ou onboarding est desactivé en abonnement
  }
}
