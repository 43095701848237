export default {
  MY_QUESTIONS: {
    MY_QUESTIONS: 'My Questions',
    ADD_QUESTION: 'Add a Question',
    NO_QUESTIONS:
      "You haven't asked any questions. If you have a question, don't hesitate to add one by clicking on the button above 😊",
    PREVIOUS: 'Previous',
    NEXT: 'Next',
    PAGINATION: 'Pagination',
    PAGE: 'page',
    YOU_ON_PAGE: "You're on page",
  },
  RECEIVED_QUESTIONS: {
    NO_QUESTIONS: 'Your team has not received any questions at this time.',
    NO_QUESTIONS_SEARCH: 'No question found.',
  },
  QUESTION_CARD: {
    TEAM: 'Team',
    TRANSVERSE_TEAM: 'Transverse team',
    STATUS: 'Status',
    CANCELED: 'Canceled',
    CANCEL: 'Cancel',
    ANSWER: 'Answer',
    ANSWERED: 'Answered',
    NOT_ANSWERED: 'Not Answered',
    ATTACHED_FILE: 'Attached file',
    ASKED_QUESTION: 'Asked question',
    TRANSFER: 'Transfer to another team',
    PRIORITY: 'Priority',
    PRORITY_LEVELS: {
      NORMAL: 'Normal',
      TOP_PRIORITY: 'Top priority',
      URGENT: 'Urgent',
    },
    SENTIMENT_ANALYSIS: {
      ANGER_LEVELS: {
        NOT_ANGRY: 'Not angry',
        LITTLE_ANGRY: 'A little angry',
        ANGRY: 'Angry',
        VERY_ANGRY: 'Very angry',
      },
      STRESS_LEVELS: {
        NOT_STRESSED: 'Not stressed',
        LITTLE_STRESSED: 'A little stressed',
        STRESSED: 'Stressed',
        VERY_STRESSED: 'Very stressed',
      },
      FRUSTRATION_LEVELS: {
        NOT_FRUSTRATED: 'Not frustrated',
        LITTLE_FRUSTRATED: 'A little frustrated',
        FRUSTRATED: 'Frustrated',
        VERY_FRUSTRATED: 'Very frustrated',
      },
    },
  },
  ADD_QUESTION: {
    ADD_QUESTION: 'Add Question',
    TITLE: 'Subject of the question',
    TITLE_REQUIRED: 'The Subject is required',
    TEAM_QUESTION: 'Team Question',
    TEAM_REQUIRED: 'Team is required',
    QUESTION: 'Question',
    QUESTION_REQUIRED: 'Question is required',
    QUESTION_MINLENGTH: 'The question must be at least 20 characters long',
    QUESTION_MAXLENGTH: 'Question exceeds maximum length of 300 characters',
    CANCEL: 'Cancel',
    INPROGRESS: 'In Progress...',
    ASK: 'Ask',
    UPLOAD_SUCCESS: 'Uploaded successfully',
    UPLOAD_FAILED: 'Failed to send',
    FILE_TYPE_ERROR: 'The file type is not allowed',
    GPT_ERROR: 'It seems that there is an error. Please try again',
  },
  CANCEL_QUESTION: {
    CONFIRM_CANCEL_QUESTION: 'Are you sure you want to cancel this question?',
    CONFIRM: 'Confirm',
    CANCEL: 'Cancel',
  },
  DROPDOWN: {
    TEAM_SELECTION: 'Select the teams to contact',
    CANCEL_SELECTION: 'Cancel selection',
    ALL_SELECTION: 'Select all',
    NO_EXISTING_TEAM: 'No existing team',
  },
  UPLOAD_FILE: {
    SELECT_FILE: 'Select File',
    CLEAR: 'Clear',
    COMPLETED: 'Completed !',
  },
  NO_REPONSE: {
    QUESTION: 'Question',
    NO_RESPONSE: 'You have cancelled this question',
    CANCEL: 'Cancel',
  },
  TRANSFER_QUESTION: {
    MODAL_HEADER: 'Transfer this question to another team',
    LABEL: 'Transfer the question to the team',
    CANCEL: 'Cancel',
    SUBMITTING: 'Transferring',
    TRANSFER: 'Transfer',
  },
  ANSWER_QUESTION: {
    QUESTION: 'Question',
    ANSWER: 'Answer',
    ANSWER_REQUIRED: 'Answer is required',
    ANSWER_MINLENGTH: 'The answer must be at least 10 characters long',
    SEND: 'Send',
    CANCEL: 'Cancel',
    EMOTION_STATE: 'Emotion State',
    STRESS: 'Stress',
    COLERE: 'Anger',
    FRUSTRATION: 'Frustration',
    EMI_FETCHED_DOCS: 'Answer generated from the following documents:',
    ATTACH_FILE: 'Attach files',
    SAVE_RESPONSE: 'Your response has been saved',
    NO_DOCS: 'No documents related to this question',
    GENERATING_ANSWER: 'Generating answer...',
  },
  QUESTION_DETAILS: {
    QUESTION: 'Question',
    RESPONSE: 'Response',
    POSTED_ON: 'Posted on',
    POSTED_BY: 'Posted by',
    RESPONDED_ON: 'Responded on',
    RESPONDED_BY: 'Responded by',
    QUESTION_FILE: 'Question file',
    RESPONSE_FILE: 'Response file',
    RECIPIENT: 'Recipient',
    ATTACHED_FILE: 'Attached file',
    CANCEL: 'Cancel',
  },
  SEARCH_FILTER: {
    SEARCH: 'Search...',
    FILTER_BY: 'Filter by status',
    ANSWERED: 'Answered',
    NOTANSWERED: 'Not answered',
    CANCELED: 'Cancelled',
    CLEAR_FILTER: 'Clear filters',
  },
  SETTINGS: {
    SAVE: 'Save',
    RESET: 'Reset',
    SUCCESS_MESSAGE: 'Successfully selected teams to contact',
    ERROR_MESSAGE: 'Error when adding the choice of teams to contact',
  },
}
